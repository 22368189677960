import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardContent,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Container,
  Grid,
  Divider,
  Chip
} from '@material-ui/core';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineItem,
  TimelineDot,
  TimelineOppositeContent,
  TimelineSeparator
} from '@material-ui/lab';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import classNames from 'classnames';

import ProfileMenu from '../ProfileMenu.js';
import { formatDate } from '../../../helper.js';


const merchantInfo = {
  name: 'Shopify Merchant',
  storeName: 'Merchant Store',
  subscriptionPlan: 'Pro Plan', // Aktueller Abonnementplan des Händlers
};
  
const useStyles = makeStyles((theme) => ({
  root: {
    margin: -12,
    width: 'auto',
    overflowX: 'auto',
    '& .MuiTimelineOppositeContent-root': {
      flex: '1 !important'
    }
  },
  table: {
    minWidth: 650,
  },
  label: {
    width: 200,
    background: '#fff',
    borderRight: 'solid 1px #ccc',
    position: 'sticky',
    left: 0,
    zIndex: 1
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    padding: 12
  },
  statusRow: {
    padding: 12
  },
  statusChip: {
    marginLeft: 16,
    fontSize: '1rem'
  },
  timeline: {
    backgroundColor: 'lightgrey',
    margin: 0,
    paddingTop: 40,
    '&.disabled': {
      opacity: .5
    }
  }
}));


export function ChargeTimeline({charge}) {
  console.log(charge)

  const classes = useStyles();

  let icon = <FiberManualRecordIcon 
    style={{
      color: (charge.status === 'active' || charge.status === 'accepted') ? 'green' : 'red'
    }}
  />

  return (<div className={classes.root}>
    <Typography className={classes.statusRow}>
      Status: 
      <Chip
        icon={icon}
        label={charge.status}
        className={classes.statusChip}
      />
    </Typography>
    <Divider />
    <Timeline className={charge.name === 'free' ? 'disabled' : null} classes={{root: classes.timeline}}>
      <TimelineItem>
        <TimelineOppositeContent>
          <Typography color="textSecondary">
            {formatDate(charge.billing_on, {futureMonth: 1})}
          </Typography>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>Next billing ($ {parseFloat(charge.price).toFixed(2)})</TimelineContent>
      </TimelineItem>
      
      <TimelineItem>
        <TimelineOppositeContent>
          <Typography color="textSecondary">
            {formatDate(charge.activated_on)}
          </Typography>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>Charge activated</TimelineContent>
      </TimelineItem>

      <TimelineItem>
        <TimelineOppositeContent>
          <Typography color="textSecondary">
            {formatDate(charge.created_at)}
          </Typography>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot />
        </TimelineSeparator>
        <TimelineContent>Charge created</TimelineContent>
      </TimelineItem>

    </Timeline>
  </div>);
}


const ProfileChargesPage = ({ classes }) => {
  const charge = useSelector((state) => state.shop.shop.charge);


  useEffect(() => {
    console.log(charge)
  }, [charge]);

  return (
    <React.Fragment>
      <Grid container spacing={3} alignItems="flex-start" className={classNames(classes.root, "page-content")}>
        <Grid item xs={12}>
          <ProfileMenu />
        </Grid>
        <Grid item xs={12} className="page-content">
          <Typography variant="h4" component="h2">My Charges</Typography>
          <Divider className="divider-white" />
        </Grid>
        <Grid item xs={9}>
          <Paper className={classes.paper}>
            <ChargeTimeline 
              charge={charge}
              classes={classes}
            />
          </Paper>
        </Grid>
        <Grid item xs={3}>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default (ProfileChargesPage);
