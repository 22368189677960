import React, { useState, useEffect, useRef } from 'react'
import withRouter from 'with-router';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useNavigate } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect, useDispatch, useSelector } from 'react-redux';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import FormLabel from '@material-ui/core/FormLabel';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import Slide from '@material-ui/core/Slide';
import Collapse from '@material-ui/core/Collapse';
import CancelIcon from '@material-ui/icons/Cancel';
import Check from '@material-ui/icons/Check';
import DoneOutlined from '@material-ui/icons/DoneOutlined';
import CloseIcon from '@material-ui/icons/Close';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';
import HorizontalSplitIcon from '@material-ui/icons/HorizontalSplit';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import LayersIcon from '@material-ui/icons/Layers';
import TranslateOutlinedIcon from '@material-ui/icons/TranslateOutlined';
import IsoRoundedIcon from '@material-ui/icons/IsoRounded';
import PlusOneRoundedIcon from '@material-ui/icons/PlusOneRounded';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import AvTimerOutlinedIcon from '@material-ui/icons/AvTimerOutlined';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Tooltip from '@material-ui/core/Tooltip';
import Fade from '@material-ui/core/Fade';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import Input from '@material-ui/core/Input';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import SyncIcon from '@material-ui/icons/Sync';
import PlaylistPlayOutlinedIcon from '@material-ui/icons/PlaylistPlayOutlined';
import PlusOneOutlinedIcon from '@material-ui/icons/PlusOneOutlined';
import Filter9PlusOutlinedIcon from '@material-ui/icons/Filter9PlusOutlined';
import GTranslateOutlinedIcon from '@material-ui/icons/GTranslateOutlined';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';

import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
//import { useSpring, animated, useResize } from '@react-spring/web'; // web.cjs is required for IE 11 support
import html2canvas from 'html2canvas';

import FadingTextComponent from './FadingTextComponent';
import RechargePlans from './RechargePlans';
import LoadingIndicator from './LoadingIndicator';
import bulkTranslation, { bulkCountWords, bulkTranslationStart, bulkTranslationGetTasks, bulkTranslationClearTasks, prepareCountWords, bulkTranslationRewind } from '../modules/bulkTranslation.js';
import { syncProducts, resyncProducts } from '../modules/products';
import { syncCollections } from '../modules/collections';
import { syncPages } from '../modules/pages';
import { syncNavigation } from '../modules/navigation';
import { syncBlogs } from '../modules/blogs';
import { syncArticles } from '../modules/articles';
import API from '../api/api';
import { Typography } from '@material-ui/core';
import { CONSOLE_LEVELS } from '@sentry/utils';
import _ from 'lodash';
import { Trans, withTranslation, useTranslation } from 'react-i18next';
import { PlusOneOutlined } from '@material-ui/icons';
import { UpgradePlanHint } from './PricingPlans.js';


export const taskKey2DataObject = (key) => {
  let split1 = key.split(':');
  let split2 = split1[2].split('_')
  return {
    store: split1[1],
    to: split2[0],
    scope: split2[1].toLowerCase(),
    type: split2[2].toLowerCase(),
  };
}



window.tasksInterval = null;
const runTasksInterval = (func, force, callback) => {
  let _force = (!force) ? false : true;
  if(_force) {
    clearInterval(window.tasksInterval);
    window.tasksInterval = null;
    //console.log(`runTasksInterval() -> Force-Cleared Interval ${window.tasksInterval}`);
  }
  if(!window.tasksInterval) {
    window.tasksInterval = setInterval(() => {
      func((data) => {
        if(data.success === true && data.tasks) {
          let stopInterval = true;
          for (let [task_key, task_value] of Object.entries(data.tasks)) {
            // Wenn nur ein Task "running" als status hat, soll das Interval nicht gestopt werden
            if(task_value.status === 'running' && stopInterval) {
              stopInterval = false;
            }
          }
          if(stopInterval) {
            clearInterval(window.tasksInterval);
          }

          if(callback) {
            callback(data);
          }
        }
      });
    }, 1000);
  } else {
    //console.log(`runTasksInterval() -> There is already another interval running`);
  }
}


function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value; //assign the value of ref to the argument
  },[value]); //this code will run when the value of 'value' changes
  return ref.current; //in the end, return the current ref value.
}

function MinusSquare(props) {
  return (<SvgIcon fontSize="inherit" style={{ width: 24, height: 24, backgroundColor: 'white', padding: 4 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
    </SvgIcon>
  );
}
function PlusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{  width: 24, height: 24, backgroundColor: 'white', padding: 4 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
    </SvgIcon>
  );
}
function TransitionComponent(props) {
  //const style = useSpring({
    //from: { opacity: 0, transform: 'translate3d(20px,0,0)' },
    //to: { opacity: props.in ? 1 : 0, transform: `translate3d(${props.in ? 0 : 20}px,0,0)` },
  //});
/*
  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
  */
  return (
      <Collapse {...props} />
  );
}
 TransitionComponent.propTypes = {
   /**
    * Show the component; triggers the enter or exit states
    */
   in: PropTypes.bool,
 };
 const StyledTreeItem = withTranslation()(withStyles((theme) => ({
   iconContainer: {
     '& .close': {
       opacity: 0.3,
     },
   },
   group: {
     marginLeft: 7,
     paddingLeft: 18,
     borderLeft: `1px dashed #ccc`,
   }
 }))((props) => {
  const classes = useStyles2();

  const { t } = props;
  //const { t } = useTranslation();

  let [running, setRunning] = useState(false);
  let [visible, setVisible] = useState(true);

  const handleLabelClick = (event, value) => {
    if(!props.disabled) {
      event.preventDefault(); 
      return true;
    } else {
      event.stopPropagation();
      return false;
    }
  };

  const handleSyncClick = (e) => {
    setRunning(true);
    props.handleSync(e, props.nodeId);
  };
 
  return <TreeItem 
    {...props} 
    //TransitionComponent={TransitionComponent} 
    //onIconClick={handleIconClick}
    onLabelClick={handleLabelClick}
    label={
      <Tooltip 
        title={(props.disabled && props.nodeId.indexOf('.') === -1) ? t('bulkTranslationFeature.settingsDialog.syncronizeInfo') : ''}
        placement="bottom-start" 
        classes={{
          popper: classes.bulkSelectedFieldsTooltipPopper
        }}
        //arrow 
        //disableFocusListener 
        //disableHoverListener 
        //disableTouchListener
      >
        <FormControlLabel 
          disabled={props.disabled}
          style={{width: '100%'}}
          classes={{label: classes.formControlLabel}}
          control={
            <Checkbox 
              checked={props.checked}
              disabled={props.disabled}
            />
          } 
          label={<div style={{width: '100%'}}>
              {props.label} 
              {props.pending &&
                <React.Fragment>
                  {(visible) && 
                    <Button size="small" variant="outlined" color="secondary" disabled={props.running} onClick={handleSyncClick} style={{float: 'right', marginRight: 8, padding: '0 4px 0 0'}}>
                      <SyncIcon className={running ? classes.rotateIcon : null} style={{ fontSize: 18 }} /> 
                      {!running ? t('bulkTranslationFeature.settingsDialog.sync') : t('bulkTranslationFeature.settingsDialog.syncronizing')}
                    </Button> 
                  }
                </React.Fragment>
              }
            </div>
          }
        >
        </FormControlLabel>
      </Tooltip>   
    }
  />
}));
 




/** 
 * +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
 * Bulk Translations Settings
 * +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
 * 
 * 
 * 
 * 
 *  
 */
const BulkTranslationSettingsComponent = (props) => {
  const languages = props.languages.languages;
  const classes = useStyles2();
  const {t} = props;

  const [activeStep, setActiveStep] = useState(0);
  const [expanded, setExpanded] = useState(['products']);
  const [bulkSelectedFields, setBulkSelectedFields] = useState([]);
  const [bulkSelectedLanguages, setBulkSelectedLanguages] = useState([]);
  const [screenLogBody, setScreenLogBody] = useState({});

  const [wordsLeft, setWordsLeft] = useState(props.shop.shop.auto_translations.available_words);
  const [totalWordCount, setTotalWordCount] = useState(props.bulkTranslation.total_words);
  const [countProgress, setCountProgress] = useState(props.bulkTranslation.count_progress);
  const [countWordsPending, setCountWordsPending] = useState(props.bulkTranslation.count_words_pending);
  const [countTasks, setCountTasks] = useState(props.bulkTranslation.count_tasks);
  const [translateWordsPending, setTranslateWordsPending] = useState(props.bulkTranslation.translate_words_pending);
  const [isTaskQuerying, setIsTaskQuerying] = useState(props.bulkTranslation.is_task_querying);
  const [showRechargePlans, setShowRechargePlans] = useState(false);
  const [showTaskErrors, setShowTaskErrors] = useState(false);
  const [cancelCounting, setCancelCounting] = useState(false);
  const [wordpack, setWordpack] = useState(null);
  const [outdated, setOutdated] = useState(false);
  const [isTranslating, setIsTranslating] = useState(false);
  const [error, setError] = useState(null);

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  // ComponentDidMount
  useEffect(() => {
    /*
    runTasksInterval(props.bulkTranslationGetTasks, true, () => {

      console.log(countProgress, totalWordCount, props.bulkTranslation.total_words);
    });
    */



    // Get currently active tasks
    props.bulkTranslationGetTasks((res) => {
      const sumBy = (obj, field) => obj
        .map(item => {
          if(!item[1]) return null;
          return item[1][field];
        })
        .reduce((prev, curr) => prev + curr, 0);

      // tasks are available
      if(res.tasks && Object.keys(res.tasks).length > 0) {
        let newSelectedFields = [];
        let newSelectedLanguages = [];
        let newOpen = false;
        for(let [taskKey, task] of Object.entries(res.tasks)) {
          newOpen = true;
          let keySegments = taskKey.split(':');
          let objects = keySegments[2].split('_')[1].toLowerCase().split(',');
          if(task.type === 'count') {
            if(!newSelectedLanguages[task.parameter.to]) {
              newSelectedLanguages.push(task.parameter.to);
            }
            if(task.parameter.sub_objects) {
              newSelectedFields = task.parameter.sub_objects.split(',');
            }
            objects.forEach((object) => {
              if(newSelectedFields.indexOf(object) === -1) {
                newSelectedFields.push(object);
              }
            });

            // Start task progress interval when there is a running count task
            if(task.status === 'running') {
              runTasksInterval(props.bulkTranslationGetTasks);
            }
          }
        }
        if(newSelectedFields.length > 0) { setBulkSelectedFields(newSelectedFields); }
        if(newSelectedLanguages.length > 0) { setBulkSelectedLanguages(newSelectedLanguages); }

        // Set stepper step
        if(Object.values(res.tasks).every(x => x.type === 'count' && x.status === 'completed')) { 
          let all_total_words = sumBy(Object.entries(res.tasks), 'WordCount');
          let all_count_jobs_done = sumBy(Object.entries(res.tasks), 'jobs_done');
          let all_count_jobs_count = sumBy(Object.entries(res.tasks), 'jobs_count');
          let new_count_progress = Math.round(all_count_jobs_done / all_count_jobs_count * 100);

          setCountProgress(new_count_progress);
          setTotalWordCount(all_total_words);

          // Nur wenn alle count tasks auf complete sind und count progress > 0 und count words < words left sonst auf step 3
          if(countWordsPending || (all_total_words > wordsLeft) || (new_count_progress === 100 && all_total_words === 0)) 
          {
            setActiveStep(2); 
          } else {
            setActiveStep(3);
          }

          // Wenn alle count tasks completed sind und totalWordCount immernoch auf 0 ist, die tasks flushen
          if(new_count_progress === 100 && all_total_words === 0) {
            //clearEntireQueue();
          }
        } 

        if(Object.values(res.tasks).find(x => x.type === 'translate')) { 
          setActiveStep(null);
        }


        // Nur öffnen, wenn es keinen translate Task gibt
        if(Object.values(res.tasks).every(x => x.type === 'count') && newOpen === true) {
          setOpen(newOpen);
        }
      }
      // no tasks available
      else {
        // use currently selected language when bulkSelectedLanguages are empty
        if(bulkSelectedLanguages.length === 0) {
          const languages = props.languages.languages;
          const currentLanguage = languages.filter(x => x.id === parseInt(props.shop.shop.edit_language))[0];
          setBulkSelectedLanguages([currentLanguage.code]);
        }
      }
    });

    // Reopen entrypoint after more words have been bought or the bulk settings dialog was closed before starting the translation process
    if(window.localStorage.getItem('ly_bulk_translate:reopen_count_dialog')) {
      let reopenData = JSON.parse(window.localStorage.getItem('ly_bulk_translate:reopen_count_dialog'));
      setTotalWordCount(reopenData.totalWordCount);
      setBulkSelectedFields(reopenData.bulkSelectedFields);
      setBulkSelectedLanguages(reopenData.bulkSelectedLanguages);
      setCountProgress(100);
      if(reopenData.totalWordCount > wordsLeft) {
        setActiveStep(2);
      } else {
        setActiveStep(3);
      }
      setOpen(true);
      window.localStorage.removeItem('ly_bulk_translate:reopen_count_dialog');
    }
  }, []);



  // ComponentWillReceiveProps
  const prevBulkRewindSettings = usePrevious(props.bulkTranslation.bulk_rewind_settings);
  useEffect(() => {
    if(props.bulkTranslation.translate_words_pending !== translateWordsPending) setTranslateWordsPending(props.bulkTranslation.translate_words_pending);
    if(props.bulkTranslation.count_tasks !== countTasks) setCountTasks(props.bulkTranslation.count_tasks);
    if(props.bulkTranslation.count_words_pending !== countWordsPending) setCountWordsPending(props.bulkTranslation.count_words_pending);
    if(props.bulkTranslation.total_words !== totalWordCount) setTotalWordCount(props.bulkTranslation.total_words);
    if(props.bulkTranslation.count_progress !== countProgress) setCountProgress(props.bulkTranslation.count_progress);
    if(props.bulkTranslation.is_task_querying !== isTaskQuerying) setIsTaskQuerying(props.bulkTranslation.is_task_querying);
    if(props.shop.shop.auto_translations.available_words !== wordsLeft) setWordsLeft(props.shop.shop.auto_translations.available_words);
    //if(props.bulkTranslation.count_words_pending) setCountProgress(props.bulkTranslation.count_progress);
    
    if(
      ((props.bulkTranslation.total_words > props.shop.shop.auto_translations.available_words) && props.bulkTranslation.count_progress === 100 && !props.bulkTranslation.count_words_pending) ||
      props.bulkTranslation.count_words_pending || 
      (props.bulkTranslation.total_words === 0 && props.bulkTranslation.count_progress === 100)
    ) {
      setActiveStep(2);
    }
    if(!props.bulkTranslation.count_words_pending && props.bulkTranslation.count_progress === 100 && 
      (props.bulkTranslation.total_words > 0) && 
      (props.bulkTranslation.total_words <= props.shop.shop.auto_translations.available_words) &&
      activeStep !== null
    ) {
      setActiveStep(3);
    }

    // Get next wordpack and display it in the button
    if(props.bulkTranslation.total_words > props.shop.shop.auto_translations.available_words && props.bulkTranslation.count_progress === 100) { // && wordpack === null) {
      loadNextWordpack(props.bulkTranslation.total_words - props.shop.shop.auto_translations.available_words)
    }

    // Rewind Bulk Settings
    if(props.bulkTranslation.bulk_rewind_settings !== prevBulkRewindSettings && props.bulkTranslation.bulk_rewind_settings === true) {
      handleResetSteps();
    }
  }, [
    props.bulkTranslation.total_words, 
    props.bulkTranslation.count_words_pending, 
    props.bulkTranslation.translate_words_pending, 
    props.bulkTranslation.count_progress, 
    props.bulkTranslation.processes, 
    props.bulkTranslation.bulk_rewind_settings, 
    props.bulkTranslation.is_task_querying,
    props.shop.shop.auto_translations.available_words,
    cancelCounting,
    wordpack
  ]);


  const prevOpen = usePrevious(props.open);
  useEffect(() => {
    if(prevOpen === false) {
      if(props.open) {
        handleOpen();
      } else {
        handleClose();
      }      
    }
  }, [props.open]);
  const handleOpen = function() {
    //setActiveStep(0);
    setOpen(true);
    //setIsTranslating(true);
    //setTimeout(makeScreenLog, 1000);
  }
  const handleClose = function(event, reason) {
    if (reason !== 'backdropClick') {
      setOpen(false);
    }
    // Clear tasks when counting is finished and there are no words left or no words found
    //if(!countWordsPending && countProgress === 100 && (totalWordCount > wordsLeft || totalWordCount === 0)) {
    //  clearEntireQueue(null);
    //  handleResetSteps();
    //}
  }

  const handleNextStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBackStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleResetSteps = () => {
    setActiveStep(0);
    setTotalWordCount(0);
    setCountProgress(0);
    setBulkSelectedLanguages([]);
    setBulkSelectedFields([]);
  };


  const handleUnselectLanguage = (event, langToDelete) => {
    setBulkSelectedLanguages((bulkSelectedLanguages) => bulkSelectedLanguages.filter((language) => language !== langToDelete));
    setTotalWordCount(0);
    setCountProgress(0);
  };
  const handleSelectBulkLanguages = (event) => {
    let theValue = [event.target.value];
    if(Array.isArray(event.target.value)) {
      theValue = event.target.value;
      if(event.target.value.length > 1) {
        theValue = bulkSelectedLanguages;
      }
    }
    setBulkSelectedLanguages(theValue);
    setTotalWordCount(0);
    setCountProgress(0);
  }

  const handleSelectBulkField = (event, nodeIds) => {
    if (event.target.nodeName === "svg") {
      return;
    }
    const first = nodeIds[0];
    if (bulkSelectedFields.includes(first)) {
      setBulkSelectedFields(bulkSelectedFields.filter(id => id !== first));
    } else {
      setBulkSelectedFields([first, ...bulkSelectedFields]);
    }
    setTotalWordCount(0);
    setCountProgress(0);
  }

  const handleStartCountProcess = () => {
    const languages = props.languages.languages;
    const baseLanguage = languages.filter(x => x.base === true)[0];
    const newSelectedFields = bulkSelectedFields.slice();//_getSelectedFieldsObject();


    if(bulkSelectedLanguages.length > 0) {
      setTotalWordCount(0);
      setCountProgress(0);

      props.prepareCountWords();


      // Clear queue
      //clearEntireQueue(() => {

        // Iterate selected fields
        let scopeObjects = '';
        let subObject = '';

        newSelectedFields.map(val => {
          if(val.indexOf('.') !== -1){
            subObject += val + ',';
          } else {
            scopeObjects += val + ',';
          }
        });

        // Iterate selected languages
        bulkSelectedLanguages.forEach((language, i) => {
          const index = i;
          setTimeout(() => {
            props.bulkCountWords(props.shopUrl, baseLanguage.code, language, scopeObjects.slice(0, -1), subObject.slice(0, -1), outdated, () => {
              if(index === bulkSelectedLanguages.length - 1) {
                runTasksInterval(props.bulkTranslationGetTasks, true);
              }
            });            
          }, index*1000);
        });
      //});
    }
  }

  const handleStartBulkTranslation = () => {
    setIsTranslating(true);
    makeScreenLog(
      {
        charge: screenLogBody.charge,
        shop: screenLogBody.shop
      },
      () => {
        setActiveStep(null);
        setTotalWordCount(0);
        setCountProgress(0);
        //setShowTaskErrors(false);
    
        if(props.showInModalDialog) {
          handleClose();
        }
    
        Object.entries(props.bulkTranslation.count_tasks).forEach(task => {
          if(task[1].WordCount > 0) {
            let taskData = taskKey2DataObject(task[0]);
            let outOfSyncString = window.localStorage.getItem('ly_bulk_translation:out_of_sync');
            outOfSyncString = `${!outOfSyncString ? '' : outOfSyncString}${taskData.to}_${taskData.scope};`;
            window.localStorage.setItem('ly_bulk_translation:out_of_sync', outOfSyncString);        
          }
        });
    
        props.bulkTranslationStart(props.shopUrl, props.bulkTranslation.count_tasks, () => {
          runTasksInterval(props.bulkTranslationGetTasks, true);
        });
      }
    );
  }

  const _isEmptyAndParentDisabled = () => {
    const bulkSelectedFieldsCheck = bulkSelectedFields.filter(item => item.indexOf('.') === -1);
    if(bulkSelectedFieldsCheck.length > 0) {
      return false;
    } else {
      return true;
    }
  }
  const _isSelectedAndOutOfSync = () => {
    const bulkSelectedFieldsCheck = bulkSelectedFields.filter(item => item.indexOf('.') === -1);
    let hasOutOfSyncObject = false;
    bulkSelectedFieldsCheck.forEach((value) => {
      if(props[value][`sync_${value}_pending`]) {
        hasOutOfSyncObject = true;
      }
    });
    return hasOutOfSyncObject;
  }

  const handleToggle = (event, nodeIds) => {
    if (event.target.nodeName !== "svg") {
      return;
    }
    setExpanded(nodeIds);
  };


  const openRechargePlans = () => {
    setShowRechargePlans(true)
  }
  const closeRechargePlans = () => {
    setShowRechargePlans(false)
  }
  const onBuyWords = (plan) => {
    API.buyWords(
      plan,
      res => {
        //console.log(res)
      }
    );

    let reopenData = {
      totalWordCount: totalWordCount,
      wordsLeft: wordsLeft,
      bulkSelectedFields: bulkSelectedFields,
      bulkSelectedLanguages: bulkSelectedLanguages
    }
    window.localStorage.setItem('ly_bulk_translate:reopen_count_dialog', JSON.stringify(reopenData));
  }


  const handleSync = (e, scope) => {
    e.stopPropagation();

    let languageId = props.editLanguage;

    switch(scope) {
      case 'products': {
        props.syncProducts(languageId);
        break;
      }
      case 'collections': {
        props.syncCollections(languageId);
        break;
      }
      case 'pages': {
        props.syncPages(languageId);
        break;
      }
      case 'navigation': {
        props.syncNavigation(languageId);
        break;
      }
      case 'blogs': {
        props.syncBlogs(languageId);
        break;
      }
      case 'articles': {
        props.syncArticles(languageId);
        break;
      }
      case 'notifications': {
        props.syncNotifications(languageId);
        break;
      }
    }
  }

  const clearEntireQueue = (callback) => {
    setCancelCounting(true);
    props.bulkTranslationGetTasks((data) => {
      let tasks = data.tasks;
      if(tasks && Object.keys(tasks).length > 0) {
          props.bulkTranslationClearTasks(tasks, () => {
              handleResetSteps();
              setCancelCounting(false);
          });
      } else {
        handleResetSteps();
        setCancelCounting(false);
      }
    });
  }


  const loadNextWordpack = (wordsNum) => {
    if(wordpack !== null) return false;

    setLoading(true);

    // Round up to next 10.000
    const roundedWordsNum = Math.ceil(wordsNum/10000)*10000;
    // Create hash
    const hash = window.btoa(roundedWordsNum);

    API.bulkTranslationGetWordpack(hash, (res) => {
      setLoading(false);
      if(res.success === true) {
        setWordpack(res.data);
      } else {
        // Error
        setWordpack(null);
        setError(res.error);
      }
    })
  }

  const buyWordpack = () => {
    const hash = wordpack.hash;
    if(hash) {
      setLoading(true);

      API.bulkTranslationBuyWordpack(hash, (res) => {
        setLoading(false);
        if(res.success === true) {
          console.log(res);
          makeScreenLog(
            {
              charge: res.response.appPurchaseOneTime.id.split('/').pop(),
              shop: res.response.confirmationUrl.split('/v2/api')[0]
            },
            () => {
              setWordpack(null);
              window.location.href = res.response.confirmationUrl;
            }
          );
        } else {
          // Error
          setWordpack(null);
          setError(res.error);
        }
      });

    }
  }

  const makeScreenLog = (data, callback) => {
    let target = document.querySelector('#bulkTranslationSetup .MuiDialog-paper');
    let options = {
      imageTimeout: 0,

    }
    html2canvas(target).then(canvas => {
      const screenshotDataUrl = canvas.toDataURL('image/png');
      const body = {
        shop: props.shopUrl,
        charge: data.charge || null,
        image: screenshotDataUrl
      }

      //window.console.log(body);
      //window.console.image(screenshotDataUrl, 10);

      API.screenLog(body);

      if(callback) setTimeout(callback, 100);
    }, options);
  }

  window.console.image = function(url, size = 100) {
    const image = new Image();
    image.src = url;
    image.onload = function() {
      var style = [
        'font-size: 1px;',
        'padding: ' + this.height/100*size + 'px ' + this.width/100*size + 'px;',
        'background: url('+ url +') no-repeat;',
        'background-size: contain;'
       ].join(' ');
       console.log('%c ', style);
    };
  };

  const renderFieldSelection = () => {
    //console.log(bulkSelectedFields.includes('products'))
    return <TreeView
       className={classes.root}
       defaultCollapseIcon={<MinusSquare />}
       defaultExpandIcon={<PlusSquare />}
       //defaultEndIcon={<CloseSquare />}
       defaultEndIcon={null}
       onNodeSelect={handleSelectBulkField}
       onNodeToggle={handleToggle}
       expanded={expanded}
       selected={bulkSelectedFields}
       multiSelect
     >
      <StyledTreeItem nodeId="products" label={t('bulkTranslationFeature.settingsDialog.products')} checked={bulkSelectedFields.includes('products') ? true : false} handleSync={handleSync} disabled={props.products.sync_products_pending} pending={props.products.sync_products_pending}>
        <StyledTreeItem nodeId="products.variants" label={t('bulkTranslationFeature.settingsDialog.variants')} checked={bulkSelectedFields.includes('products.variants') ? true : false} disabled={(props.products.sync_products_pending || !bulkSelectedFields.includes('products')) ? true : false} />
        <StyledTreeItem nodeId="products.options" label={t('bulkTranslationFeature.settingsDialog.options')} checked={bulkSelectedFields.includes('products.options') ? true : false} disabled={(props.products.sync_products_pending || !bulkSelectedFields.includes('products')) ? true : false} />
        {/*<StyledTreeItem nodeId="products.metafields" label="Metafields" checked={bulkSelectedFields.includes('products.metafields') ? true : false} disabled={(props.products.sync_products_pending || !bulkSelectedFields.includes('products')) ? true : false} />*/}
      </StyledTreeItem>
      <StyledTreeItem nodeId="collections" label={t('bulkTranslationFeature.settingsDialog.collections')} checked={bulkSelectedFields.includes('collections') ? true : false} handleSync={handleSync} disabled={props.collections.sync_collections_pending} pending={props.collections.sync_collections_pending}>
        {/*<StyledTreeItem nodeId="collections.metafields" label="Metafields" checked={bulkSelectedFields.includes('collections.metafields') ? true : false} disabled={props.collections.sync_collections_pending || !bulkSelectedFields.includes('collections') ? true : false} />*/}
      </StyledTreeItem>
      <StyledTreeItem nodeId="pages" label={t('bulkTranslationFeature.settingsDialog.pages')} checked={bulkSelectedFields.includes('pages') ? true : false} handleSync={handleSync} disabled={props.pages.sync_pages_pending} pending={props.pages.sync_pages_pending}>
        {/*<StyledTreeItem nodeId="pages.metafields" label="Metafields" checked={bulkSelectedFields.includes('pages.metafields') ? true : false} disabled={props.pages.sync_pages_pending || !bulkSelectedFields.includes('pages') ? true : false} />*/}
      </StyledTreeItem>
      <StyledTreeItem nodeId="navigation" label={t('bulkTranslationFeature.settingsDialog.navigation')} checked={bulkSelectedFields.includes('navigation') ? true : false} handleSync={handleSync} disabled={props.navigation.sync_navigation_pending} pending={props.navigation.sync_navigation_pending} />
      <StyledTreeItem nodeId="blogs" label={t('bulkTranslationFeature.settingsDialog.blogs')} checked={bulkSelectedFields.includes('blogs') ? true : false} handleSync={handleSync} disabled={props.blogs.sync_blogs_pending} pending={props.blogs.sync_blogs_pending}>
        {/*<StyledTreeItem nodeId="blogs.metafields" label="Metafields" checked={bulkSelectedFields.includes('blogs.metafields') ? true : false} disabled={props.blogs.sync_blogs_pending || !bulkSelectedFields.includes('blogs') ? true : false} />*/}
      </StyledTreeItem>
      <StyledTreeItem nodeId="articles" label={t('bulkTranslationFeature.settingsDialog.articles')} checked={bulkSelectedFields.includes('articles') ? true : false} handleSync={handleSync} disabled={props.articles.sync_articles_pending} pending={props.articles.sync_articles_pending} >
        {/*<StyledTreeItem nodeId="articles.metafields" label="Metafields" checked={bulkSelectedFields.includes('articles.metafields') ? true : false} disabled={props.articles.sync_articles_pending || !bulkSelectedFields.includes('articles') ? true : false} />*/}
      </StyledTreeItem>
     </TreeView>
   }

   const renderBulkLanguagesSelection = () => {
    return <FormControl variant="outlined" className={classes.formControl} fullWidth>
      {bulkSelectedLanguages.length === 0 &&
        <Typography className={classes.languagesSelectorPlaceholder}>
          {t('bulkTranslationFeature.settingsDialog.step1.languageSelectPlaceholder')}
        </Typography>
      }
      <Select
        id="bulk-mutiple-languages-chip"
        max
        multiple={false}
        displayEmpty
        value={bulkSelectedLanguages}
        onChange={handleSelectBulkLanguages}
        renderValue={(selected) => (
          <div className={classes.chips}>
            {bulkSelectedLanguages.map((value) => {
              const currentLanguage = props.languages.languages.filter(x => x.code == value)[0];
              if(!currentLanguage) {
                return null;
              }
              return <Chip 
                key={value} 
                label={currentLanguage.name} 
                className={classes.chip} 
                deleteIcon={<CancelIcon onMouseDown={(e) => { e.stopPropagation() }} />}
                onDelete={(e) => handleUnselectLanguage(e, value)}
              />
            })}
          </div>
        )}
        fullWidth
        inputProps={{ 
          'aria-label': 'Without label'
        }}
        MenuProps={{
          classes: { 
            paper: classes.menuPaper 
          },
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left"
          },
          getContentAnchorEl: null
        }}
        style={{
          paddingLeft: '4px !important',
        }}
      >
        {props.languages.languages.map((language) => {
          if(language.base === true) {
            return null;
          }
          return (
            <MenuItem key={language.id} value={language.code}>
              {language.name}
            </MenuItem>
          )}
        )}
      </Select>
      <FormHelperText>{t('bulkTranslationFeature.settingsDialog.step1.languageSelectInfo')}</FormHelperText>
    </FormControl>
   }

   const renderStepperActionsContainer = () => {
      return <div className={classes.actionsContainer}>
        {_isSelectedAndOutOfSync() && activeStep === 1 &&
          <Typography variant="caption" display="block" gutterBottom style={{color: 'red'}}>
            {t('bulkTranslationFeature.settingsDialog.step2.info1')}
          </Typography>
        }
        <div>
          {(countWordsPending || Object.keys(countTasks).length > 0) ?
            <Button
              disabled={cancelCounting ||
                        (activeStep === 0 && bulkSelectedLanguages.length === 0) || 
                        (activeStep === 1 && (bulkSelectedFields.length === 0 || _isEmptyAndParentDisabled() || _isSelectedAndOutOfSync()))}
              variant="outlined"
              color="error"
              onClick={clearEntireQueue}
              className={classes.cancelTasksBtn} 
              sx={{ color: "error.main" }}
              startIcon={<ClearOutlinedIcon />}
            >
              {cancelCounting && 
                <span className={classes.loadingSpinnerContainer}>
                  <CircularProgress className={classes.loadingSign} size={25} />
                </span>
              }
              {cancelCounting ? t('bulkTranslationFeature.settingsDialog.cancelBtnPending') : t('bulkTranslationFeature.settingsDialog.cancelBtn')}
            </Button>
          :
            <Button
              disabled={activeStep === 0 || activeStep === 3 || Object.keys(countTasks).length > 0}
              onClick={handleBackStep}
              className={classes.button}
              variant="outlined"
            >
              <KeyboardBackspaceIcon style={{marginRight: 4}} /> {t('bulkTranslationFeature.settingsDialog.prevBtn')}
            </Button>                
          }

          {activeStep <= 1 && 
            <Button
              disabled={(activeStep === 0 && bulkSelectedLanguages.length === 0) || 
                        (activeStep === 1 && (bulkSelectedFields.length === 0 || _isEmptyAndParentDisabled() || _isSelectedAndOutOfSync()))}
              variant="contained"
              color="secondary"
              onClick={handleNextStep}
              className={classes.button}
            >
              {t('bulkTranslationFeature.settingsDialog.nextBtn')}
            </Button>
          }

          {activeStep === 2 && 
            <React.Fragment>
              <Button
                disabled={countWordsPending || cancelCounting}
                variant="contained"
                color="secondary"
                onClick={handleStartCountProcess}
                className={classes.button}
                startIcon={<PlusOneOutlinedIcon />}
              >
                {!countWordsPending ? 
                  <React.Fragment>
                    {countProgress === 100 ? t('bulkTranslationFeature.settingsDialog.countBtnAgain') : t('bulkTranslationFeature.settingsDialog.countBtn')}                  
                  </React.Fragment>
                  :
                  t('bulkTranslationFeature.settingsDialog.countBtnPending')
                }
                <span className={classes.loadingSpinnerContainer}>
                  {countWordsPending &&
                    <CircularProgress className={classes.loadingSign} size={25} />
                  }
                </span>
              </Button>
              
              <Button
                //disabled={props.bulkTranslation.count_words_pending && totalWordCount <= wordsLeft && totalWordCount > 0 && countProgress === 100}
                disabled={
                  countWordsPending ||
                  (countProgress < 100) ||
                  (totalWordCount > wordsLeft) ||
                  (totalWordCount === 0 && countProgress === 100)
                }
                variant="contained"
                color="secondary"
                onClick={handleNextStep}
                className={classes.button}
              >
                {t('bulkTranslationFeature.settingsDialog.nextBtn')}
              </Button>
            </React.Fragment>
          }

          {activeStep === 3 && 
            <Button 
              disabled={cancelCounting || isTranslating}
              variant="contained" 
              color="secondary" 
              className={classes.button} 
              onClick={handleStartBulkTranslation}
              startIcon={<PlaylistPlayOutlinedIcon />}
            >
              {t('bulkTranslationFeature.settingsDialog.startBtn')}
            </Button>
          }
        </div>
      </div>
    }


   const renderStepper = () => {
    return <div id="BulkTranslationsConfigurationPanel" className={classes.root}>
      <Typography>
        {t('bulkTranslationFeature.settingsDialog.headDescription')}
      </Typography>
      {/*
      <Button variant="outlined" size="small" onClick={clearEntireQueue}>Clear entire queue</Button>
      <Button variant="outlined" size="small" onClick={() => {runTasksInterval(props.bulkTranslationGetTasks, true)}}>Run Interval</Button>
      */}
      <Stepper 
        activeStep={activeStep} 
        className={classes.stepperRoot} 
        orientation="vertical"
      >
        <Step key={'step_1'}>
          <StepLabel>{t('bulkTranslationFeature.settingsDialog.step1.title')}</StepLabel>
          <StepContent>
            {renderBulkLanguagesSelection()}
            {renderStepperActionsContainer()}
          </StepContent>
        </Step>

        <Step key={'step_2'}>
          <StepLabel StepIconComponent={null}>{t('bulkTranslationFeature.settingsDialog.step2.title')}</StepLabel>
          <StepContent>

            <FormControl component="fieldset" className={classes.formControl}>
              <FormLabel component="legend">{t('bulkTranslationFeature.settingsDialog.step2.fieldsLabel')}</FormLabel>
              <Typography variant="body2" style={{margin: '8px 0'}}>
                {t('bulkTranslationFeature.settingsDialog.step2.info1')}
              </Typography>
              {renderFieldSelection()}
            </FormControl>

            <Divider className={classes.divider} />

            <FormControl component="fieldset" className={classes.formControl}>
              <FormLabel component="legend">{t('bulkTranslationFeature.settingsDialog.step2.outdatedLabel')}</FormLabel>
              <Typography variant="body2" style={{margin: '8px 0'}}>
                {t('bulkTranslationFeature.settingsDialog.step2.outdatedInfo')}
              </Typography>
              <FormControlLabel
                control={<Checkbox checked={outdated} onChange={(e) => {setOutdated(!outdated)}} name="outdated" />}
                label={t('bulkTranslationFeature.settingsDialog.step2.outdatedLabel')}
              />
            </FormControl>

            {renderStepperActionsContainer()}
          </StepContent>
        </Step>

        <Step key={'step_3'}>
          <StepLabel>{t('bulkTranslationFeature.settingsDialog.step3.title')}</StepLabel>
          <StepContent>
            <Fade in={countProgress < 100} mountOnEnter={true} unmountOnExit={true}>
              <Typography variant="body1" classes={{ root: classes.text }} gutterBottom id="alert-dialog-description">
                {t('bulkTranslationFeature.settingsDialog.step3.info1')}
              </Typography>
            </Fade>

            <Fade in={countWordsPending && (countProgress <= 100)} mountOnEnter={true} unmountOnExit={true}>
              <DialogContentText variant="body1">{t('bulkTranslationFeature.settingsDialog.step3.countProgressInfo', {countProgress, totalWordCount})}</DialogContentText>
            </Fade>
                
            <Fade in={isTaskQuerying} mountOnEnter={true} unmountOnExit={true}>
              <FadingTextComponent 
                texts={[
                  bulkSelectedFields.includes('products.variants') ? 'Fetching Variants...' : null,
                  bulkSelectedFields.includes('products.options') ? 'Fetching Options...' : null,
                ]} 
              />
            </Fade>



            <Fade in={!countWordsPending && countProgress === 100 && (totalWordCount > wordsLeft)} mountOnEnter={true} unmountOnExit={true}>
              <>
                <Typography gutterBottom>
                  <Trans i18nKey={'bulkTranslationFeature.settingsDialog.step3.notEnoughWordsLeft'} 
                    values={{
                      totalWordCount: new Intl.NumberFormat('en-US').format(totalWordCount),
                      missingWordCount: new Intl.NumberFormat('en-US').format(totalWordCount - wordsLeft)
                    }}
                  >
                    Your selection contains <strong>{new Intl.NumberFormat('en-US').format(totalWordCount)}</strong> words, but you don't have enough words left to translate everything. Please purchase at least <strong>{() => {new Intl.NumberFormat('en-US').format(totalWordCount - wordsLeft)}}</strong> words.
                  </Trans>
                </Typography>
                {wordpack && !loading &&
                  <DialogContentText variant="body1">
                    <Trans i18nKey={'bulkTranslationFeature.settingsDialog.step3.wordpackRecommendation'} 
                      values={{
                        price: wordpack.price_formatted,
                        packsize: new Intl.NumberFormat( 'en-US', { maximumFractionDigits: 1,notation: "compact" , compactDisplay: "short" }).format(wordpack.count),
                        wordpackName: wordpack.name
                      }}
                    >
                      We recommend our <strong>{wordpack.name}</strong> auto-translation wordpack with {new Intl.NumberFormat( 'en-US', { maximumFractionDigits: 1,notation: "compact" , compactDisplay: "short" }).format(wordpack.count)} words for the price of { wordpack.price_formatted }
                    </Trans>
                  </DialogContentText>                
                }
                <ButtonGroup variant="outlined" color="primary" 
                  size="small"
                  disabled={(!wordpack || loading)}
                  //<className={classes.button} 
                  //style={{display: 'inline-block'}} 
                  aria-label="split button">
                  <Button onClick={buyWordpack} disabled={!wordpack || loading} size="small">
                    <span className={classes.loadingSpinnerContainer}>
                      {loading &&
                        <CircularProgress className={classes.loadingSign} size={25} />
                      }
                    </span>
                    {(!wordpack) ?
                      t('bulkTranslationFeature.settingsDialog.buyBtn', {
                        price: '',
                        packsize: ''
                      })
                      :
                      t('bulkTranslationFeature.settingsDialog.buyBtn', {
                        price: wordpack.price_formatted,
                        packsize: new Intl.NumberFormat( 'en-US', { maximumFractionDigits: 1,notation: "compact" , compactDisplay: "short" }).format(wordpack.count)
                      })
                    }
                  </Button>
                  <Button onClick={openRechargePlans} style={{height: 36.5}} size="small">
                    <MoreHorizIcon />
                  </Button> 
                </ButtonGroup>              
              </>
            </Fade>

            <Fade in={!countWordsPending && countProgress === 100 && totalWordCount === 0} mountOnEnter={true} unmountOnExit={true}>
              <DialogContentText variant="body1">{t('bulkTranslationFeature.settingsDialog.step3.foundNoWords')}</DialogContentText>
            </Fade>

            {!countWordsPending && countProgress === 100 && (totalWordCount > 0 && totalWordCount <= wordsLeft) &&
              <DialogContentText variant="body1">
                <Trans i18nKey={'bulkTranslationFeature.settingsDialog.step3.foundWords'} totalWordCount={totalWordCount}>
                  Found <strong>{{ totalWordCount }}</strong> words to translate.
                </Trans>
              </DialogContentText>
            }

            {(props.bulkTranslation.task_errors.length > 0 && countProgress === 100) &&
              <DialogContentText variant="body1" classes={{ root: classes.errorText }}>
                <Tooltip title={showTaskErrors ? 'Hide all errors' : 'Show all errors'} arrow>
                  <span onClick={(e) => {e.preventDefault(); setShowTaskErrors(!showTaskErrors);}} style={{cursor: 'pointer'}}>{t('bulkTranslationFeature.settingsDialog.step3.taskErrors', {errorCount: props.bulkTranslation.task_errors.length})}</span>
                </Tooltip>
                <Collapse in={showTaskErrors}>
                  {props.bulkTranslation.task_errors.map(item => <Typography><small>{item}</small></Typography>)}
                </Collapse>
              </DialogContentText>
            }

            {renderStepperActionsContainer()}
          </StepContent>
        </Step>

        <Step key={'step_4'}>
          <StepLabel>{t('bulkTranslationFeature.settingsDialog.step4.title')}</StepLabel>
          <StepContent>
            <React.Fragment>
              <Typography variant="body1" classes={{ root: classes.text }} gutterBottom id="alert-dialog-description">
                {t('bulkTranslationFeature.settingsDialog.step4.successInfo')}
              </Typography>

              <DialogContentText variant="body1">
                <Trans i18nKey={'bulkTranslationFeature.settingsDialog.step3.foundWords'} totalWordCount={totalWordCount}>
                Found <strong>{{ totalWordCount }}</strong> words to translate.
                </Trans>
              </DialogContentText>
            </React.Fragment>
            {renderStepperActionsContainer()}
          </StepContent>
        </Step>
      </Stepper>

      <RechargePlans
        visible={showRechargePlans}
        //wordNeeded={(totalWordCount > wordsLeft) ? totalWordCount - wordsLeft : 0}
        wordNeeded={10000}
        onClose={closeRechargePlans}
        onBuy={onBuyWords}
      />
    </div>
   }
   
   return <React.Fragment>
    {props.showInModalDialog ?
      <React.Fragment>

        {(props.shop.shop.charge.name === 'free' || props.shop.shop.charge.name === 'basic') ?
          <div style={{ position: 'relative' }}>
            <Button 
              variant="contained" 
              color="primary" 
              disabled={true}
              style={{paddingRight: 80}}
            >
              {t('bulkTranslationFeature.settingsDialog.title')}
            </Button>  
            <UpgradePlanHint
              title="Plan update needed"
              features={[
                'Bulk translate entire sections',
              ]}
              placement={'bottom'}
              neededPlan={'growth'}
              position="end"
              isOverlay
              isBlock
            />        
          </div>
        :
          <Button 
            variant="contained" 
            color="primary" 
            onClick={handleOpen}
            disabled={props.bulkTranslation.unfinished_process}
          >
            {t('bulkTranslationFeature.settingsDialog.title')}
          </Button>   
        }


        <Dialog
          id="bulkTranslationSetup"
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          BackdropProps={{
            style: {
              backgroundColor: 'rgba(255, 255, 255, .5)'
            }
          }}
        >
          <DialogTitle id="alert-dialog-title">
            <Badge color="default" badgeContent="NEW" classes={{badge: classes.badge}}>
              {t('bulkTranslationFeature.settingsDialog.title')}
            </Badge>
          </DialogTitle>
            <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          <DialogContent>
            {renderStepper()}
          </DialogContent>
        </Dialog>
      </React.Fragment>
    :
      renderStepper()
    }
  </React.Fragment>
 }
 const useStyles2 = makeStyles((theme) => ({
  badge: {
    backgroundColor: '#ececec',
    padding: '4px 8px',
    transform: 'scale(1) translate(100%, -25%)',
  },
  root: {
    width: '100%',
  },
  stepperRoot: {
    padding: '24px 0',
  },
  button: {
    margin: theme.spacing(1)/2,
  },
  actionsContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(4),
    marginLeft: -theme.spacing(1)/2
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  menuPaper: {
    maxHeight: 225,
  },
  errorText: {
    color: 'red !important',
  },
  languagesSelectorPlaceholder: {
    position: 'absolute',
    padding: '11px 16px',
    fontStyle: 'italic',
    color: 'grey'
  },
  chip: {
    margin: '-8px 4px',
  },
  loadingSign: {
    position: 'relative',
    margin: 4,
  },
  loadingSpinnerContainer: {
    margin: 'auto', 
    top: 1,
    position: 'absolute',
  },
  divider: {
    margin: '12px 0 12px 0'
  },
  formControl: {
    //marginBottom: 12
  },
  formControlLabel: {
    width: '100%',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  rotateIcon: {
    animation: "$spin 2s linear infinite"
  },
  "@keyframes spin": {
    "0%": {
      transform: "rotate(360deg)"
    },
    "100%": {
      transform: "rotate(0deg)"
    }
  },
  bulkSelectedFieldsTooltipPopper: {
   zIndex: '100000 !important',
   marginTop: -40,
  },
  cancelTasksBtn: {
    margin: theme.spacing(1)/2,
    /*
    borderColor: 'red',
    color: 'red',
    '&:hover': {
      backgroundColor: 'red',
      color: 'white',
    }
    */
  }
}));
const mapStateToProps2 = ({ shop, stats, bulkTranslation, languages, products, collections, pages, navigation, blogs, articles, notifications }) => ({
  shop,
  languages,
  stats: stats,
  editLanguage: shop.shop.edit_language,
  shopUrl: shop.shop.url,
  bulkTranslation,
  products, 
  collections, 
  pages, 
  navigation, 
  blogs, 
  articles, 
  notifications
});
const mapDispatchToProps2 = dispatch =>
  bindActionCreators(
    {
      prepareCountWords,
      bulkCountWords,
      bulkTranslationStart,
      bulkTranslationGetTasks,
      bulkTranslationClearTasks,
      syncProducts,
      syncCollections,
      syncPages,
      syncNavigation,
      syncBlogs,
      syncArticles,
    },
    dispatch
  )
export const BulkTranslationSettings = withRouter(connect(
  mapStateToProps2,
  mapDispatchToProps2
)(withTranslation()(BulkTranslationSettingsComponent)));
























/**
 * +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
 * Bulk Translation Status Card Component
 * +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
 * 
 * Durch alle session storage entries durchiterieren und alle entries mit "ly_bulk_translate:" im Namen sammeln.
 * "ly_bulk_translate:<SHOP URL>:<TARGET LANGUAGE>_<SCOPE>" Prefix aus den Namen der gefundenen Entries entfernen und den rest bei '_' splitten damit man an die entsprechende editLanguage sowie den Scope gelangt
 * 
 * 
 * 
 */
function BulkTranslationStatusCardComponent(props) {
  const { t } = useTranslation();
  const languages = props.languages.languages;
  const currentLanguage = languages.filter(x => x.id === parseInt(props.shop.shop.edit_language))[0];
  const baseLanguage = languages.filter(x => x.base === true)[0];

  const classes = useStyles();

  const [showCard, setShowCard] = useState(false);
  const [showTaskErrors, setShowTaskErrors] = useState(false);
  const [targetLanguageCode, setTargetLanguageCode] = useState(null);
  const [translateProgress, setTranslateProgress] = useState(props.bulkTranslation.count_progress);
  const [translateWordsPending, setTranslateWordsPending] = useState(props.bulkTranslation.translate_words_pending);
  const [allTranslateJobsCount, setAllTranslateJobsCount] = useState(props.bulkTranslation.all_translate_jobs_count);
  const [allTranslateJobsDone, setAllTranslateJobsDone] = useState(props.bulkTranslation.all_translate_jobs_done);
  const [translateTasks, setTranslateTasks] = useState(props.bulkTranslation.translste_tasks);
  const [outOfSyncSections, setOutOfSyncSections] = useState({});
  const [outOfSyncButtons, setOutOfSyncButtons] = useState([]);
  const [isSyncing, setIsSyncing] = useState(false);



  useEffect(() => {
    props.bulkTranslationGetTasks((res) => {
      if(res.tasks) {
        for(let [taskKey, task] of Object.entries(res.tasks)) {
          let cacheKey = taskKey;
          let keySegments = taskKey.split(':');
          let objects = keySegments[1].split('_')[1];

          // Start the progress interval when there is a translate task
          if(task.type === 'translate' && task.status === 'running') {
            runTasksInterval(props.bulkTranslationGetTasks);
            //break;
          }
        }
      }
    });

    if(window.localStorage.getItem('ly_bulk_translation:out_of_sync')) {
      setShowCard(true);
    }
  }, []);

  useEffect(() => {
    if(props.bulkTranslation.translate_progress !== translateProgress) setTranslateProgress(props.bulkTranslation.translate_progress);
    if(props.bulkTranslation.translate_words_pending !== translateWordsPending) setTranslateWordsPending(props.bulkTranslation.translate_words_pending);
    if(props.bulkTranslation.all_translate_jobs_count !== allTranslateJobsCount) setAllTranslateJobsCount(props.bulkTranslation.all_translate_jobs_count);
    if(props.bulkTranslation.all_translate_jobs_done !== allTranslateJobsDone) setAllTranslateJobsDone(props.bulkTranslation.all_translate_jobs_done);
    if(!_.isEqual(props.bulkTranslation.translate_tasks, translateTasks)) setTranslateTasks(props.bulkTranslation.translate_tasks);
    
    if(
      (props.bulkTranslation.translate_words_pending) || 
      (props.bulkTranslation.translate_progress === 100 && !isSyncing)
    ) {
      setShowCard(true);
    } else {
      //setShowCard(false);
    }

    let outOfSyncString = window.localStorage.getItem('ly_bulk_translation:out_of_sync');
    if(outOfSyncString) {
      let outOfSyncLanguages = outOfSyncString.split(';');
      let newOutOfSyncSections = {};
      let newOutOfSyncButtons = [];
      outOfSyncLanguages.forEach(item => {
        if(item !== '') {
          let language = item.split('_')[0]
          let objects = item.split('_')[1].split(',');
          newOutOfSyncSections[language] = objects;
          for(let i=0; i<objects.length; i++) {
            if(!newOutOfSyncButtons.find(x => x.object === objects[i])) {
              newOutOfSyncButtons.push({
                object: objects[i],
                to: language
              });
            }
          }
        }
      });
      setOutOfSyncSections(newOutOfSyncSections); 
      setOutOfSyncButtons(newOutOfSyncButtons);     
    }

  }, [
    props.bulkTranslation.all_translate_jobs_count, 
    props.bulkTranslation.all_translate_jobs_done, 
    props.bulkTranslation.processes, 
    props.bulkTranslation.translate_words_pending, 
    props.bulkTranslation.translate_progress, 
    window.localStorage.getItem('ly_bulk_translation:out_of_sync')
  ]);

  const handleSyncProcesses = async () => {
    for(const language in outOfSyncSections) {
      for(const scope of outOfSyncSections[language]) {
        //console.log({language, scope})
        await handleSync(scope, language);
      }
    }
    
    //setShowCard(false);
  }
  const handleSync = async (scope, to) => {
    let languageId = languages.find(x => x.code.toLowerCase() == to.toLowerCase()).id;

    //const utc = new Date().toUTCString();
    //console.log(`handleSync(${scope}, ${to}): ${utc}`)

    setIsSyncing(true);

    switch(scope) {
      case 'products': {
        props.syncProducts(languageId, true, () => {_manageOutOfSyncString('products', to);});
        break;
      }
      case 'collections': {
        props.syncCollections(languageId, true, () => {_manageOutOfSyncString('collections', to);});
        break;
      }
      case 'pages': {
        props.syncPages(languageId, true, () => {_manageOutOfSyncString('pages', to);});
        break;
      }
      case 'navigation': {
        props.syncNavigation(languageId, () => {_manageOutOfSyncString('navigation', to);});
        break;
      }
      case 'blogs': {
        props.syncBlogs(languageId, () => {_manageOutOfSyncString('blogs', to);});
        break;
      }
      case 'articles': {
        props.syncArticles(languageId, true, () => {_manageOutOfSyncString('articles', to);});
        break;
      }
    }

    //_manageOutOfSyncString(scope, to);
  }

  const _manageOutOfSyncString = (scope, to) => {
    // LocalStorage
    let outOfSyncString = window.localStorage.getItem('ly_bulk_translation:out_of_sync');
    if(outOfSyncString) {
      //debugger;
      let outOfSyncLanguages = outOfSyncString.split(';');
      let newOutOfSyncLanguages = [];
      let newOutOfSyncSections = {};
      let newOutOfSyncButtons = [];

      outOfSyncLanguages.forEach(value => {
        if(value !== '') {
          let newValue = value;
          newValue = newValue.replace(`${scope},`, '').replace(`,${scope}`, '').replace(`${scope}`, '');
          if(newValue !== `${newValue.split('_')[0]}_`) {
            newOutOfSyncLanguages.push(newValue);
            newOutOfSyncSections[to] = newValue.split('_')[1].split(',');
          }
        }
      });
      outOfSyncButtons.forEach(button => {
        if(button.object !== scope) {
          newOutOfSyncButtons.push(button);
        }
      })
      setOutOfSyncSections(newOutOfSyncSections); 
      setOutOfSyncButtons(newOutOfSyncButtons);    
    
      if(newOutOfSyncLanguages.length === 0) {
        window.localStorage.removeItem('ly_bulk_translation:out_of_sync');
        setIsSyncing(false);
        //setTimeout(() => {
          setShowCard(false);
          props.bulkTranslationRewind();
        //}, 500);
      } else {
        window.localStorage.setItem('ly_bulk_translation:out_of_sync', newOutOfSyncLanguages.join(';'));
      }

      // Ensure that the status dialog is closed when there is no outOfSync string
    } else {
      setIsSyncing(false);
      //setTimeout(() => {
        setShowCard(false);
        props.bulkTranslationRewind();
      //}, 500);
    }

    //const utc = new Date().toUTCString();
    //console.log(`_manageOutOfSyncString(${scope}, ${to}): ${utc}`)
  }

  return <div style={{position: 'fixed', bottom: 0, right: 12, width: 'auto', height: showCard ? 'auto' : 0, minHeight: 0, zIndex: 100000}}>
    <Slide 
      in={showCard} 
      appear={false}
      direction="up" 
      timeout={{ 
        enter: 1000, 
        exit: translateWordsPending ? 1 : 900 
      }}
    >
      <Paper elevation={5} style={{padding: '12px', minWidth: 350, maxWidth: 400}}>
        <Typography variant="h5" gutterBottom className={(!translateWordsPending && translateProgress === 100 ? classes.successText : null)}>{t('bulkTranslationFeature.statusDialog.title')}</Typography>
        <Divider style={{margin: '0 -12px'}} />
        <LinearProgress variant="determinate" value={translateProgress} style={{position: 'absolute', width: '100%', marginTop: -4, left: 0}} />

        {!translateWordsPending && translateProgress === 100 && 
          <Typography variant="body1" className={classes.successText} style={{marginTop: 8}}>
            {t('bulkTranslationFeature.statusDialog.completedInfo', {scope: props.bulkTranslation.scope})} <DoneAllIcon style={{marginRight: 12}}/>
          </Typography>
        } 
        {translateWordsPending &&
          <React.Fragment>
            <Typography variant="body1" className={classes.text} style={{marginTop: 8}}>
              {t('bulkTranslationFeature.statusDialog.processRunning', {translateProgress})}
            </Typography>
            <Typography variant="caption">
                {t('bulkTranslationFeature.statusDialog.fetchedTasks')} {allTranslateJobsCount} <br />
                {t('bulkTranslationFeature.statusDialog.doneTasks')} {allTranslateJobsDone}
            </Typography>
          </React.Fragment>
        }
        {!translateWordsPending && Object.keys(outOfSyncSections).length > 0 &&
          <div>
            <Typography variant="body2" style={{marginTop: 8}}>
              {t('bulkTranslationFeature.statusDialog.finishedSyncronizeInfo')} 
            </Typography>
            {/*
              <Divider style={{margin: '8px 0 4px 0'}} />
            {outOfSyncButtons.map(button => {
              return <Button key={`out-of-sync-btn-${button.object}`} 
                  onClick={() => { handleSync(button.object, button.to); }} 
                  variant="outlined" 
                  size="small" 
                  fullWidth 
                  //gutterBottom
                  color="secondary"
                  style={{justifyContent: "flex-start", marginTop: 4}}
                >
                  <SyncIcon className={false ? classes.rotateIcon : null} style={{ fontSize: 18, marginRight: 8 }} /> {t('bulkTranslationFeature.statusDialog.sync')} - {button.object}
                </Button>
              })
            }
            */}
            {outOfSyncButtons.length > 0 &&
              <React.Fragment>
                <Divider style={{margin: '8px 0 4px 0'}} /> 
                <Button 
                  onClick={handleSyncProcesses} 
                  variant="contained" 
                  fullWidth 
                  color="secondary"
                  style={{justifyContent: "flex-start", marginTop: 4}}
                  size="large"
                  disabled={isSyncing}
                >
                  {isSyncing &&
                    <span className={classes.loadingSpinnerContainer}>
                      <CircularProgress className={classes.loadingSign} size={25} />
                    </span>
                  }
                  <SyncIcon className={false ? classes.rotateIcon : null} style={{ fontSize: 18, marginRight: 8 }} /> {t('bulkTranslationFeature.statusDialog.syncAll')}
                </Button>             
              </React.Fragment>
            }
          </div>
        }
        {(props.bulkTranslation.task_errors.length > 0) &&
          <Typography variant="body1" classes={{ root: classes.errorText }}>
            <Tooltip title={showTaskErrors ? 'Hide all errors' : 'Show all errors'} arrow>
              <span onClick={(e) => {e.preventDefault(); setShowTaskErrors(!showTaskErrors);}} style={{cursor: 'pointer'}}>{props.bulkTranslation.task_errors.length} {t('bulkTranslationFeature.statusDialog.translationErrors')}</span>
            </Tooltip>
            <Collapse in={showTaskErrors}>
              {props.bulkTranslation.task_errors.map(item => <Typography><small>{item}</small></Typography>)}
            </Collapse>
          </Typography>
        }
      </Paper>
    </Slide>
  </div>
}

const useStyles = makeStyles({
  badge: {
    color: '#1a237e',
    backgroundColor: '#ececec',
    padding: '4px 8px',
    transform: 'scale(1) translate(100%, -25%)',
  },
  ctaBtn: {
    marginBottom: 12,
  },
  countWordsBtn: {
    //marginTop: 8,
  },
  loadingSign: {
    position: 'relative',
    margin: 4,
  },
  iconRight: {
    marginLeft: 12,
  },
  loadingSpinnerContainer: {
    margin: 'auto', 
    top: 1,
    position: 'absolute',
    textAlign: 'center',
  },
  successText: {
    color: 'green !important',
  },
  errorText: {
    color: 'red !important',
  },
  text: {
    color: 'rgba(0, 0, 0, 0.87) !important',
  },
  rotateIcon: {
    animation: "$spin 2s linear infinite"
  },
  "@keyframes spin": {
    "0%": {
      transform: "rotate(360deg)"
    },
    "100%": {
      transform: "rotate(0deg)"
    }
  }
});
 
const mapStateToProps1 = ({ shop, stats, bulkTranslation, languages }) => ({
  shop: shop,
  languages: languages,
  stats: stats,
  editLanguage: shop.shop.edit_language,
  shopUrl: shop.shop.url,
  bulkTranslation: bulkTranslation,
});
const mapDispatchToProps1 = dispatch =>
  bindActionCreators(
    {
      bulkCountWords,
      bulkTranslationStart,
      bulkTranslationGetTasks,
      resyncProducts,
      syncProducts,
      syncCollections,
      syncPages,
      syncNavigation,
      syncBlogs,
      syncArticles,
      bulkTranslationRewind,
    },
    dispatch
  )
export const BulkTranslationStatusCard = withRouter(connect(
  mapStateToProps1,
  mapDispatchToProps1
)(BulkTranslationStatusCardComponent));