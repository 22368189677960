import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import DOMPurify from 'dompurify';
import TimeAgo from 'timeago-react';
import { Grid, Collapse } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import ConfirmationNumberOutlinedIcon from '@material-ui/icons/ConfirmationNumberOutlined';
import SendOutlinedIcon from '@material-ui/icons/SendOutlined';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined';
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined';
import ReplyOutlinedIcon from '@material-ui/icons/ReplyOutlined';
import AttachFileOutlinedIcon from '@material-ui/icons/AttachFileOutlined';
import { DropzoneArea, DropzoneAreaBase, DropzoneDialogBase } from 'material-ui-dropzone';

import { Alert, AlertTitle } from '@material-ui/lab';
import { Trans, useTranslation } from 'react-i18next';
import { getContact, saveContact, getTicket, createTicket, replyTicket } from '../../../modules/ticketsystem.js'
import RedactorX from '../../../components/RedactorXWrapper';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    background: '#ffffff',
    borderRadius: 4,
    boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)'
  },
  mainContainer: {
    display: 'flex',
    minHeight: '75vh',
    position: 'relative',
    background: '#ffffff',
    //overflow: 'hidden',
    borderRadius: 4,
    boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)'
  },
  drawer: {
    position: 'sticky',
    top: 0,
    //bottom: 0,
    width: 240,
    height: '100%',
    flexShrink: 0,
    float: 'left',
  },
  drawerPaper: {
    width: 240,
    left: 'auto',
    right: 'auto',
    top: 'auto',
    bottom: 'auto',
    position: 'relative',
    background: 'none', 
  },
  content: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    display: 'relative',
    marginLeft: -1,
    borderLeft: 'solid 1px #ddd',
    width: 'calc(100% - 240px)',
  },
  appBar: {
    zIndex: 100,
    position: 'sticky',
    top: 40,
    height: 65,
    background: '#eeeeee',
    color: 'initial',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  appBarIcon: {
    marginTop: -5,
    width: '2rem',
    height: '2rem',
  },
  toolbar: theme.mixins.toolbar,
  createNewTicketBtn: {
    position: 'absolute',
    right: 16
  },
  chatContainer: {
    flexGrow: 1,
    padding: theme.spacing(2),
    //overflowY: 'auto',
  },
  inputContainer: {
    position: 'sticky',
    bottom: -30,
    zIndex: 1,
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderTop: `1px solid ${theme.palette.divider}`,
    backgroundColor: '#fff',
    '&.creation': {
      flexDirection: 'row',
    },
    '& .MuiCollapse-root': {
      width: '100%'
    }
  },
  textField: {
    marginRight: theme.spacing(2),
    flexGrow: 1,
    //margin: '16px 8px',
  },
  contactCreationForm: {
    justifyContent: 'start !important',
  },
  fullHeightInfo: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    height: '100%',
    flexWrap: 'wrap',
    flexDirection: 'column'
  },
  loadingSign: {
    position: 'relative',
    top: '50%',
    left: '50%',
  },
  loadingSpinnerContainer: {
    top: 0,
    left: 0,
    bottom: 0,
    right:0,
    position: 'absolute',
    zIndex: 10,
    backgroundColor: 'rgba(255,255,255,.5)',
  },
  message: {
    padding: '24px 12px',
    '&.own': {
      backgroundColor: '#efefef',
      borderRadius: 8,
      margin: 16
    }
  },
  ticketIdLabel: {
    position: 'absolute',
    right: 24,
  },
  ticketDateLabel: {
    marginLeft: 16
  }
}));

const Ticketsystem = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [loaded, setLoaded] = React.useState(false);
  const [contactCreationStep, setContactCreationStep] = React.useState(0);
  const [selectedTicket, setSelectedTicket] = React.useState(null);
  const [messages, setMessages] = React.useState([]);
  const [newFirstname, setNewFirstname] = React.useState('');
  const [newLastname, setNewLastname] = React.useState('');
  const [newEmail, setNewEmail] = React.useState('');
  const [newSubject, setNewSubject] = React.useState('');
  const [newMessage, setNewMessage] = React.useState('');
  const [creationMode, setCreationMode] = React.useState(false);
  const [showReplyForm, setShowReplyForm] = React.useState(false);
  const [showSpamInfo, setShowSpamInfo] = React.useState(false);
  const [showSuccessInfo, setShowSuccessInfo] = React.useState(false);
  const [showErrorInfo, setShowErrorInfo] = React.useState(false);
  const [openAttachmentsDialog, setOpenAttachmentsDialog] = React.useState(false);
  const [attachments, setAttachments] = React.useState([])

  const contact = useSelector((state) => state.ticketsystem.contact);
  const tickets = useSelector((state) => state.ticketsystem.tickets);
  const get_contact_pending = useSelector((state) => state.ticketsystem.get_contact_pending);
  const create_contact_pending = useSelector((state) => state.ticketsystem.create_contact_pending);
  const create_ticket_pending = useSelector((state) => state.ticketsystem.create_ticket_pending);
  const get_ticket_pending = useSelector((state) => state.ticketsystem.get_ticket_pending);
  const reply_ticket_pending = useSelector((state) => state.ticketsystem.reply_ticket_pending);
  
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  
  useEffect(() => {
    dispatch(
      getContact((res) => {
        setLoaded(true);

        if(res.success === false) {
          setContactCreationStep(0);
        }
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (id && tickets.length > 0 && selectedTicket === null && !loaded) {
      setTimeout(() => {
        let ticketIndex = tickets.findIndex(x => x.id === id);
        if (ticketIndex >= 0) {
          handleTicketSelect(ticketIndex);
        }
      }, 1000);
    }
  }, [tickets, id, selectedTicket, loaded]);

  const handleSaveContact = () => {
    dispatch(
      saveContact(
        newFirstname, 
        newLastname, 
        newEmail, 
        (res) => {
          if(res.success === true) {
            setContactCreationStep(1);
            setShowSuccessInfo(true);
            if(res.activationEmailSend === true){
              setShowSpamInfo(true);
            } else {
              // reload ticketsystem
              setTimeout(() => {
                props.doReloadComponent(Math.random()*100);
              }, 3000)
            }
          } else {
            setShowErrorInfo(true);
          }
        }
      )
    );
  }

  const handleCreateNewTicket = () => {
    setNewSubject('');
    setNewMessage('');
    setSelectedTicket(null);
    setShowSpamInfo(false);
    setShowSuccessInfo(false);
    setCreationMode(true);
  }

  const handleTicketSelect = (index) => {
    setCreationMode(false); 
    setShowReplyForm(false);
    dispatch(getTicket(tickets[index].id, () => {
      history.push(`/profile/tickets/${tickets[index].id}`);
      setSelectedTicket(tickets[index]);
    }));
  };

  const handleTicketDeselect = () => {
    setCreationMode(false); 
    setShowReplyForm(false);
    setSelectedTicket(null);
    history.push('/profile/tickets');
  };

  const handleSendMessage = () => {
    if (newMessage.trim() !== '') {
      if (creationMode) {
        const formData = new FormData();
        formData.append('subject', newSubject);
        formData.append('description', newMessage);
        attachments.forEach(attachment => {
          formData.append('attachments[]', attachment.file);
        });
        dispatch(createTicket(formData, () => {
          setMessages([...messages, newMessage]);
          setCreationMode(false); 
          setShowReplyForm(false);
          setNewMessage('');
        }));
      } else {
        const formData = new FormData();
        formData.append('body', newMessage);
        attachments.forEach(attachment => {
          formData.append('attachments[]', attachment.file);
        });
        dispatch(replyTicket(selectedTicket.id, formData, () => {
          setMessages([...messages, newMessage]);
          setCreationMode(false); 
          setShowReplyForm(false);
          setNewMessage('');
        }));
      }
    }
  };

  const sortTicketsByUpdatedAt = (tickets) => {
    return tickets.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
  }

  const handleShowReplyForm = () => {
    setShowReplyForm(true);
  }

  const handleHideReplyForm = () => {
    setShowReplyForm(false);
  }

  const downloadFile = (fileLink, fileName) => {
    const link = document.createElement('a');
    link.href = fileLink;  // Replace with your actual file link
    link.setAttribute('download', fileName); // You can specify a custom filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);  // Clean up the DOM
  };

  const renderContactForm = () => {
    return (
      <div style={{padding: 4}}>
        {contactCreationStep === 0 &&
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h4">
                {t('ticketsystem.createNewContact')}
              </Typography>
              <Typography variant="subtitle1">
                {t('ticketsystem.contactDescription')}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} spacing={2}>
              <TextField
                variant="outlined"
                label={t('ticketsystem.firstname')}
                value={newFirstname}
                onChange={(e) => setNewFirstname(e.target.value)}
                className={classes.textField}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />        
            </Grid>
            <Grid item xs={12} md={6} spacing={2}>
              <TextField
                variant="outlined"
                label={t('ticketsystem.lastname')}
                value={newLastname}
                onChange={(e) => setNewLastname(e.target.value)}
                className={classes.textField}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />        
            </Grid>
            <Grid item xs={12} spacing={2}>
              <TextField
                variant="outlined"
                label={t('ticketsystem.email')}
                value={newEmail}
                onChange={(e) => setNewEmail(e.target.value)}
                className={classes.textField}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />        
            </Grid>
            <Grid item xs={12} alignContent="end" spacing={2}>
              <Button onClick={handleSaveContact} variant="contained" color="secondary"
                disabled={newEmail === '' || newFirstname === '' || newLastname === ''}  
              >
                {t('ticketsystem.saveContact')}
              </Button>        
            </Grid>
          </Grid>
        }
        {contactCreationStep === 1 &&
          <>
            {showErrorInfo &&
              <Alert severity="error" elevation={3} gutterBottom>
                <strong>{t('ticketsystem.contactCreatedSuccess')}</strong>
              </Alert>
            }
            {showSuccessInfo &&
              <Alert severity="success" elevation={3} gutterBottom>
                <strong>{t('ticketsystem.contactCreatedSuccess')}</strong>
              </Alert>
            }
            {showSpamInfo &&
              <Alert severity="warning" elevation={3} gutterBottom>
                {t('ticketsystem.activationEmailSent', { email: newEmail })}<br/>
                {t('ticketsystem.checkSpamFolder')}
              </Alert>
            }
          </>
        }
      </div>
    );
  }

  const renderReplyForm = (showingOnStartup) => {
    return (
      <div className={classNames(classes.inputContainer, (showingOnStartup || showReplyForm) ? 'creation' : null)}>
        <Divider />
        <Collapse in={!showingOnStartup ? showReplyForm : true} fullWidth>
          <Grid spacing={2} container>
            {creationMode && 
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  placeholder={t('ticketsystem.subjectPlaceholder')}
                  label={t('ticketsystem.subject')}
                  value={newSubject}
                  onChange={(e) => setNewSubject(e.target.value)}
                  className={classes.textField}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />        
              </Grid>
            }  
            <Grid item xs={12}>
              <RedactorX 
                config={{
                  plugins: ['alignment', 'removeformat', 'fontcolor', 'underline'],
                  buttons: {
                    addbar: ['paragraph', 'table', 'embed', 'quote', 'pre', 'line'],
                    editor: ['add', 'format', 'file'],
                    context: ['bold', 'italic', 'underline', 'link'],
                    toolbar: [],
                    topbar: ['html'],
                  },
                  addbar: {
                    add: [],
                    hide: ['quote', 'pre'],
                  },
                  subscribe: {
                    
                  }
                }}
                placeholder={t('ticketsystem.messagePlaceholder')}
                label={t('ticketsystem.message')}
                value={newMessage}
                onChange={(data) => { 
                  setNewMessage(data);
                }}
              /> 
            </Grid>
            <Grid item xs={12}>
              <ButtonGroup disableElevation variant="contained" size="small">
                <Button size="small" onClick={() => {setOpenAttachmentsDialog(true)}}>
                  <AttachFileOutlinedIcon /> {t('ticketsystem.attachments')} {` (${attachments.length})`}
                </Button>
                <Button
                  disabled={(create_ticket_pending || reply_ticket_pending || newMessage === '' || (newSubject === '' && creationMode))}
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={handleSendMessage}
                >
                  {creationMode ? <AddOutlinedIcon /> : <ReplyOutlinedIcon />}
                  {creationMode ? t('ticketsystem.create') : t('ticketsystem.reply')}
                </Button>  
              </ButtonGroup>        
            </Grid>
          </Grid>   

          
          <DropzoneDialogBase
            //dialogTitle={dialogTitle()}
            acceptedFiles={['image/*', 'video/*', 'application/zip']}
            fileObjects={attachments}
            cancelButtonText={t('ticketsystem.cancel')}
            submitButtonText={t('ticketsystem.addAttachment')}
            maxFileSize={2000000}
            open={openAttachmentsDialog}
            onAdd={newFileObjs => {
              console.log('onAdd', newFileObjs);
              setAttachments([].concat(attachments, newFileObjs));
            }}
            onDelete={deleteFileObj => {
              console.log('onDelete', deleteFileObj);
              setAttachments(attachments.filter(x => x.file.name !== deleteFileObj.file.name));
            }}
            onClose={() => setOpenAttachmentsDialog(false)}
            onSave={() => {
              console.log('onSave', attachments);
              setOpenAttachmentsDialog(false);
            }}
            showPreviews={true}
            showFileNamesInPreview={true}
          />    
        </Collapse>

        {!showReplyForm && !showingOnStartup &&
          <Grid spacing={2} container>
            <Grid item xs={12}>
              <Button variant="contained" color="secondary" onClick={handleShowReplyForm}>
                {t('ticketsystem.writeReply')}
              </Button>
            </Grid>
          </Grid>
        }
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" noWrap>
            {selectedTicket === null ?
              <>
                {creationMode ?
                  <><IconButton onClick={handleTicketDeselect}><ArrowBackOutlinedIcon /></IconButton> {t('ticketsystem.createTicket')}</>
                :
                  <><ConfirmationNumberOutlinedIcon className={classes.appBarIcon} /> {t('ticketsystem.title')}</>
                }
              </>
            :
              <><IconButton onClick={handleTicketDeselect}><ArrowBackOutlinedIcon /></IconButton> {selectedTicket.subject}</>
            }
          </Typography>
          {selectedTicket !== null &&
            <>
              <Typography align="right" variant="body2" className={classes.ticketDateLabel}>
                <TimeAgo datetime={selectedTicket.updated_at} />
              </Typography>
              <Typography align="right" variant="body2" className={classes.ticketIdLabel}>
                {` #${selectedTicket.id}`}
              </Typography>            
            </>
          }
          {contact && !creationMode && selectedTicket === null &&
            <Button className={classes.createNewTicketBtn} variant="contained" color="secondary" onClick={handleCreateNewTicket}>
              <AddOutlinedIcon /> {t('ticketsystem.createNewTicket')}
            </Button>
          }
        </Toolbar>
      </AppBar>
      <div className={classes.mainContainer}>
        <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <List>
            {sortTicketsByUpdatedAt(tickets).map((ticket, index) => (
              <ListItem button key={`ticket-${ticket.id}`} onClick={() => handleTicketSelect(index)} selected={ticket.id === selectedTicket?.id}>
                <ListItemText 
                  primary={<>{ticket.subject}<small>{` #${ticket.id}`}</small></>} 
                  primaryTypographyProps={{noWrap: true}}
                  secondary={<TimeAgo datetime={ticket.updated_at} />} 
                  secondaryTypographyProps={{noWrap: true}}
                />
              </ListItem>
            ))}
          </List>
        </Drawer>
        {loaded && <main className={classes.content}>
          {!creationMode && selectedTicket !== null &&
            <div className={classes.chatContainer}>
              <div className={classes.message}>
                <Typography variant={'caption'}>
                  <strong>{t('ticketsystem.ticketCreatedAt')} </strong>
                  <TimeAgo datetime={selectedTicket.created_at} />
                </Typography>
                <Divider />
                <div className={classes.message} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(selectedTicket?.ticket?.description) }}></div>
                {selectedTicket?.ticket?.attachments?.length > 0 &&
                  <>
                    <Divider/>
                    <Typography variant="caption" component="p">Attachments:</Typography>
                    {selectedTicket.ticket.attachments.map(attachment => <span>{JSON.stringify(attachment)}</span>)}
                  </>
                }
              </div>
              {selectedTicket?.conversations && selectedTicket.conversations.map((message, index) => (
                <div className={classNames(classes.message, message.user_id === contact.id ? 'own' : null)} key={index}>
                  <Typography variant={'caption'}>
                    <strong>{message.user_id === contact.id ? t('ticketsystem.repliedAt') : t('ticketsystem.respondedAt')} </strong>
                    <TimeAgo datetime={message.updated_at} />
                  </Typography>
                  <Divider />
                  <div className={classes.message} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(message.body) }}></div>
                  {message?.attachments?.length > 0 &&
                    <>
                      <Divider/>
                      <Typography variant="caption" component="p">Attachments:</Typography>
                      {message.attachments.map(attachment => <Typography variant="caption" component="p">
                        <a href={attachment.attachment_url} target="_blank" download={attachment.name}
                          //onClick={() => {
                          //downloadFile(attachment.attachment_url, attachment.name);
                          //}}
                        >{attachment.name}</a>
                      </Typography>)}
                    </>
                  }
                </div>
              ))}
            </div>
          }
          
          {!contact &&
            <div className={classNames(classes.contactCreationForm, classes.fullHeightInfo)} style={{ padding: 16 }}>
              { renderContactForm() }
            </div>
          }

          {contact && !creationMode && selectedTicket === null && tickets.length > 0 && 
            <Typography className={classes.fullHeightInfo}>{t('ticketsystem.noTicketSelected')}</Typography>
          }
          
          {contact && !creationMode && selectedTicket === null && tickets.length === 0 && 
            <div className={classes.fullHeightInfo}>
              <Typography gutterBottom>{t('ticketsystem.noTicketCreated')}</Typography>
              <Typography align="center">
                <Button variant="contained" color="secondary" onClick={handleCreateNewTicket}>
                  {t('ticketsystem.createNewTicket')}
                </Button>
              </Typography>
            </div>
          }

          {(creationMode || selectedTicket !== null) &&
            renderReplyForm(creationMode)
          }

          {(get_contact_pending || create_contact_pending || create_ticket_pending || get_ticket_pending || reply_ticket_pending) && 
            <span className={classes.loadingSpinnerContainer}>
              <CircularProgress className={classes.loadingSign} size={25} />
            </span>
          }
        </main>}
      </div>
    </div>
  );
};

export default Ticketsystem;
